/*Font Imports*/
@font-face {
  font-family: 'Product Sans Bold Italic', sans-serif;
  src: local('Product Sans Bold Italic'), url('./assets/fonts/Product Sans Bold Italic.ttf') format('truetype');
}
@font-face {
  font-family: 'Product Sans', sans-serif;
  src: local('Product Sans Bold'), url('./assets/fonts/Product Sans Bold.ttf') format('truetype'),;
  font-weight: bold;
}
@font-face {
  font-family: 'Product Sans', sans-serif;
  src: local('Product Sans Italic'), url('./assets/fonts/Product Sans Italic.ttf') format('truetype');
  font-weight: italic;
}
@font-face {
  font-family: 'Product Sans', sans-serif;
  src: local('Product Sans Regular'), url('./assets/fonts/Product Sans Regular.ttf') format('truetype');
  font-weight: normal;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* ----------- TinyEMC Editor ------------ */ 

.tox-tinymce{
  border: 1px solid #c4c4c4 !important;
  margin-bottom: 20px;
}
.tox-fullscreen {
  margin-top: 60px !important;
  border: 1px solid #cacaca !important;
  border-radius: 0px !important;
  height: calc(100% - 60px) !important;
}
.tox-tbtn--disabled:hover{
  background-color: transparent !important;
  color: #90979e !important;
}
.tox-mbtn:hover{
  background: rgba(220, 17, 62, 0.04) !important;
  color: rgba(220, 17, 62, 1) !important;
  border-radius: 4px !important;
}
.tox-tbtn[aria-disabled="false"]:hover{
  background: rgba(220, 17, 62, 0.04) !important;
  color: rgba(220, 17, 62, 1) !important;
  border-radius: 4px !important;
}
.tox-tbtn[aria-disabled="false"]:hover svg{
  fill: rgba(220, 17, 62, 1) !important;
}
.tox-tbtn--enabled{
  background: rgba(220, 17, 62, 0.04) !important;
  color: rgba(220, 17, 62, 1) !important;
  border-radius: 4px !important;
}
.tox-tbtn--enabled svg{
  fill: rgba(220, 17, 62, 1) !important;
}
.tox-mbtn--active{
  background-color: rgba(220, 17, 62, 0.04) !important;
  color: rgba(220, 17, 62, 1) !important;
  border-radius: 4px !important;
}
.tox-collection__item--state-disabled[aria-disabled="true"]:hover{
  background-color: transparent !important;
}
.tox-swatches__picker-btn:hover, .tox-swatches__picker-btn:active{
  background-color: rgba(220, 17, 62, 0.04) !important;
  box-shadow: none !important;
  border-radius: 4px !important;
}
.tox-swatches__picker-btn:hover svg, .tox-swatches__picker-btn:active svg {
  fill: rgba(220, 17, 62, 1) !important;
}
.tox-collection__item--active[aria-disabled="false"]{
  background-color: rgba(220, 17, 62, 0.04);
  color: rgba(220, 17, 62, 1) !important;
  border-radius: 4px !important;
}
.tox-collection__item--active[aria-disabled="false"] .tox-collection__item-caret svg{
  fill: rgba(220, 17, 62, 1) !important;
}
.tox:not([dir="rtl"]) .tox-collection--list .tox-collection__item > :not(:first-child){
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.tox-editor-header{
  border-bottom: 1px solid #c4c4c4 !important;
}
.tox-statusbar{
  border-top: 1px solid #c4c4c4 !important;
}
.tox .tox-listboxfield .tox-listbox--select, .tox .tox-textarea, .tox .tox-textfield, .tox .tox-toolbar-textfield{
  margin-top: 5px !important;
  border-color: #c4c4c4 !important;
  border-radius: 4px !important;
}
.tox-rgba-preview {
  border-radius: 4px;
}
.tox-sv-palette-spectrum {
  border-radius: 4px;
}
.tox-hue-slider-spectrum{
  border-radius: 4px;
}
.tox-dialog__title, .tox-label, .tox-dialog__body-nav-item{
  font-family: "Google Sans",Roboto,Arial,sans-serif !important;
}
.tox-button{
  font-weight: 500 !important;
  font-family: "Google Sans",Roboto,Arial,sans-serif !important;
  border-radius: 4px !important;
  
}
.tox-button[title="Guardar"]{
  background-color: rgba(220, 17, 62, 1) !important;
  border-color: rgba(220, 17, 62, 1) !important;
}

.tox-dialog__footer-end button[title="Cerrar"]{
  background-color: rgba(220, 17, 62, 1) !important;
  border-color: rgba(220, 17, 62, 1) !important;
}

.tox-dialog__footer-end button[title="Cerrar"]:hover{
  background-color: rgba(220, 17, 62, 0.8) !important;
  border-color: rgba(220, 17, 62, 0.8) !important;
}

.tox-button--naked[title="Cerrar"]{
  background-color: transparent !important;
  border-color: transparent !important;
}

.tox-button--naked[title="Cerrar"]:hover{
  background-color: #e4e5e7 !important;
}

.tox .tox-dialog__body-nav-item--active {
  padding: 5px !important;
  border-top-left-radius: 4px !important;
  border-top-right-radius: 4px !important;
}
.tox .tox-dialog__body-nav-item {
  padding: 5px !important;
}

.tox:not([dir="rtl"]) .tox-color-input span {
  margin-top: 5px;
}

.tox .tox-dialog__body-nav-item--active {
  border-bottom: 2px solid rgba(220, 17, 62, 1) !important;
  color: rgba(220, 17, 62, 1) !important;
}

.tox .tox-split-button:hover {
  box-shadow: 0 0 0 1px #dc113e1c inset !important;
}

.tox .tox-split-button:focus {
  background: rgba(220, 17, 62, 0.04) !important;
  box-shadow: none !important;
  color: rgba(220, 17, 62, 1) !important;
}

.tox .tox-collection--toolbar .tox-collection__item--enabled {
  background: rgba(220, 17, 62, 0.04) !important;
  color: rgba(220, 17, 62, 1) !important;
}

.tox .tox-collection--toolbar .tox-collection__item--active {
  background: rgba(220, 17, 62, 0.04) !important;
}

.tox .tox-dialog__body-nav-item:focus {
  background-color: rgba(220, 17, 62, 0.04) !important;
}

.tox .tox-listboxfield .tox-listbox--select:focus, .tox .tox-textarea:focus, .tox .tox-textfield:focus {
  box-shadow: none !important;
  border-color: rgba(220, 17, 62, 1) !important;
}

.tox .tox-collection--list .tox-collection__item--active[aria-disabled="false"] {
  background-color: rgba(220, 17, 62, 0.04);
}

.tox .tox-insert-table-picker .tox-insert-table-picker__selected {
  background-color: rgba(220, 17, 62, 0.06) !important;
  border-color: rgba(220, 17, 62, 1) !important;
}

/* --------------------------------------- */ 